import { useInfiniteQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const fetchFacts = ({ pageParam = 1 }) => {
  const newEndpoints = endpoints.facts.getFacts.replace(
    "?page={pagenumber}",
    "?page=" + pageParam
  );
  return http().get(newEndpoints);
};

export function useFetchFacts() {
  return useInfiniteQuery(["fetchFacts"], fetchFacts, {
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page < lastPage.meta.total)
        return lastPage.meta.current_page + 1;
      return false;
    },
  });
}
